import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { AngularFireAuth } from "@angular/fire/auth";
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  userInfo: any;
  idToken: string;
  refreshToken: string;

  constructor(public afAuth: AngularFireAuth, private httpClient: HttpClient) { }

  async  loginWithGoogle() {
    try {
      let provider = new firebase.auth.GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account',
        access_type: 'offline',
        response_type: 'code'
      });
      var result: any = await this.afAuth.signInWithPopup(provider);
      this.userInfo = result;
      this.idToken = result.credential.idToken;
      window.localStorage.setItem('userinfo', JSON.stringify(this.userInfo.user));
      return result;
    } catch (error) {
      console.log('error=========', error)
      return error;
    }
  }

  async logout() {
    await this.afAuth.signOut();
    window.localStorage.removeItem('userinfo');
  }

  refreshTokenGenerater() {
    return new Promise((resolve, reject) => {
      this.afAuth.onAuthStateChanged((user) => {
        if (user) {
          user.getIdToken(true).then((token) => {
            let storage = JSON.parse(window.localStorage.getItem('userinfo'));
            storage['stsTokenManager']['accessToken'] = token;
            window.localStorage.setItem('userinfo', JSON.stringify(storage));
            resolve();
          }).catch((error) => {
            reject();
          });
        } else {
        }
      });
    })
  }

  verifyGloberPermissions() {
    let url = `${environment.baseUrl}/auth/verify-admin`;
    let accessToken = JSON.parse(window.localStorage.getItem('userinfo')).stsTokenManager.accessToken;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'authToken': this.idToken, 'authorization': `Bearer ${accessToken}` });
    return this.httpClient.get(url, { headers: headers }).toPromise();
  }

  async getAppList() {
    //await this.refreshTokenGenerater();
    let url = `${environment.baseUrl}/dashboard/app`;
    let accessToken = JSON.parse(window.localStorage.getItem('userinfo')).stsTokenManager.accessToken;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': `Bearer ${accessToken}` });
    return this.httpClient.get(url, { headers: headers }).toPromise();
  }

  getAppHistory(appId) {
    let url = `${environment.baseUrl}/dashboard/apps/${appId}/history`;
    let accessToken = JSON.parse(window.localStorage.getItem('userinfo')).stsTokenManager.accessToken;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': `Bearer ${accessToken}` });
    return this.httpClient.get(url, { headers: headers }).toPromise();
  }

  changeFeaturedApp(appId, oldAppId) {
    let url = `${environment.baseUrl}/dashboard/apps/markAsFeatured`;
    let accessToken = JSON.parse(window.localStorage.getItem('userinfo')).stsTokenManager.accessToken;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': `Bearer ${accessToken}` });
    this.httpClient.put(url, {
      "oldAppId": oldAppId,
      "newAppId": appId
    }, { headers: headers }).toPromise().then(() => { }).catch((err) => { console.log(err) });
  }

  getUniqueStorageIdentifier(appname) {
    let url = `${environment.baseUrl}/dashboard/apps/store-app/create?action=identifier`;
    let accessToken = JSON.parse(window.localStorage.getItem('userinfo')).stsTokenManager.accessToken;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': `Bearer ${accessToken}` });
    return this.httpClient.post(url, { 'name': appname }, { headers: headers }).toPromise();
  }

  submitAppData(obj) {
    let url = `${environment.baseUrl}/dashboard/apps/store-app/create?action=submit`;
    let accessToken = JSON.parse(window.localStorage.getItem('userinfo')).stsTokenManager.accessToken;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': `Bearer ${accessToken}` });
    return this.httpClient.post(url, obj, { headers: headers }).toPromise();
  }

  updateAppLinkData(obj, key) {
    let url = `${environment.baseUrl}/dashboard/apps/store-app/${key}/update`;
    let accessToken = JSON.parse(window.localStorage.getItem('userinfo')).stsTokenManager.accessToken;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': `Bearer ${accessToken}` });
    return this.httpClient.put(url, obj, { headers: headers }).toPromise();
  }

  getUniqueIdentifierForNewApp(form) {
    let url = `${environment.baseUrl}/dashboard/apps/publish-app/create?action=verify`;
    let accessToken = JSON.parse(window.localStorage.getItem('userinfo')).stsTokenManager.accessToken;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': `Bearer ${accessToken}` });
    let payload = {
      'name': form.newAppLinkName,
      'version': form.newAppVersion,
      'bIdentifier': form.newAppBunbleIdf
    }
    return this.httpClient.post(url, payload, { headers: headers }).toPromise();
  }

  submitApp(payload) {
    let url = `${environment.baseUrl}/dashboard/apps/publish-app/create?action=submit`;
    let accessToken = JSON.parse(window.localStorage.getItem('userinfo')).stsTokenManager.accessToken;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': `Bearer ${accessToken}` });
    return this.httpClient.post(url, payload, { headers: headers }).toPromise();
  }

  updateApp(payload, appId) {
    let url = `${environment.baseUrl}/dashboard/apps/publish-app/${appId}/update`;
    let accessToken = JSON.parse(window.localStorage.getItem('userinfo')).stsTokenManager.accessToken;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': `Bearer ${accessToken}` });
    return this.httpClient.put(url, payload, { headers: headers }).toPromise();
  }

  toggleAppStatus(appId, action) {
    let url = `${environment.baseUrl}/dashboard/apps/${appId}/status`;
    let accessToken = JSON.parse(window.localStorage.getItem('userinfo')).stsTokenManager.accessToken;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': `Bearer ${accessToken}` });
    return this.httpClient.put(url, { 'action': action }, { headers: headers }).toPromise();
  }

  fakeLogin(token?: string, isProd?: boolean) {
    let url = `${environment.baseUrl}/auth/verify-admin`;
    let accessToken = (token.toLowerCase() == 'ritesh.menon@globant.com') ? '7A847B7BF88A83A7A847B7BF88A83A5AAD524AE41B425AAD524AE41MCU1' : '7A847B7BF88A83A7A847B7BF88A83A5AAD524AE41B425AAD524AE41MCU2'
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'authToken': token, 'authorization': `Bearer ${accessToken}` });
    return this.httpClient.get(url, { headers: headers }).toPromise();
  }
}
