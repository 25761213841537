import { Component, OnInit, Output, Input, EventEmitter, SimpleChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserValidators, FileTypeValidatior } from '../customError';
import { AuthenticationService } from '../service/authentication.service';
import { StorageService } from '../service/storage.service';
import { FileValidator } from 'ngx-material-file-input';
import { DrapAndDropComponent } from '../drap-and-drop/drap-and-drop.component'

@Component({
  selector: 'update-app',
  templateUrl: './update-app.component.html',
  styleUrls: ['./update-app.component.css']
})
export class UpdateAppComponent implements OnInit {
  @Input() updateAppData;
  @Output() close = new EventEmitter();
  @Output() imageUrlUp = new EventEmitter();
  @ViewChild(DrapAndDropComponent) dragComponent: DrapAndDropComponent;
  app: any;
  currentAppLogo: string;
  updateAppPreForm: FormGroup;
  updateAppForm: FormGroup;
  summaryForm: FormGroup;
  files2: any; files1: any; files3: any; files4: any;
  percentage: any = 0;
  downloadUrl: any;
  percentageIpa: any = 0;
  downloadUrlIpa: any;
  percentageVideo: any = 0;
  downloadUrlVideo: any;
  percentageBnnr: any = 0;
  downloadUrlBnnr: any;
  hideIpaField: boolean = true;
  hideAppLogoField: boolean = true;
  hideVideoField: boolean = true;
  hideBannerField: boolean = true;
  storageIdentifier: string;
  isChecked: boolean = true;
  typeOfUpdate = [{ id: '1', name: 'Mandatory', },
  { id: '2', name: 'Optional' }]
  isSubmitting: boolean = false;
  ipaUploadTask: any;
  videoUplodTask: any;
  showSummayPage: boolean = false;
  screenPayload: any = [];
  JSON: any;
  videoDeleted:boolean = false;
  videoDeletedUrl:string="";

  constructor(private authenticationService: AuthenticationService, private service: UserValidators,
    private storageService: StorageService, private cdr: ChangeDetectorRef) {
    this.initializeUpdateAppPreForm();
    this.initializeUpdateAppForm();
    this.disableFileInputs();
    this.JSON = JSON;
    console.log('Form Valid', this.updateAppForm.valid);
    console.log('app logo valid', this.updateAppForm.controls['newAppLogo'].valid);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.updateAppData.currentValue) {
      this.app = changes.updateAppData.currentValue;
      this.storageIdentifier = this.app.id;
      this.currentAppLogo = changes.updateAppData.currentValue.logo;
      this.updateAppPreForm.get('newAppLinkName').setValue(this.app.name);
      let typeOfUpdateKey = (this.app.typeOfUpdate) ? parseInt(this.app.typeOfUpdate) - 1 : 1;
      this.updateAppPreForm.get('typeOfUpdate').setValue(this.typeOfUpdate[typeOfUpdateKey]);
      this.updateAppPreForm.get('newAppVersion').setValue(this.app.version);
      this.updateAppPreForm.get('newAppVersion').disable();
      this.updateAppForm.get('newAppDescription').setValue(this.app.details.description);
      this.updateAppForm.get('newAppInstruction').setValue(this.app.details.instruction);
      this.hideVideoField = (this.app?.video && this.app?.video?.path && this.app?.video?.name) ? true : false;
      this.isChecked = !this.app.isDisabled
    }
  }

  initializeUpdateAppPreForm() {
    this.updateAppPreForm = new FormGroup({
      newAppLinkName: new FormControl('', [
        Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]+$/)
      ], [this.service.userValidator()]),
      newAppVersion: new FormControl('', [
        Validators.required, this.service.DuplicacyValidatior('version'), Validators.pattern(/^[1-9][0-9]*(\.\d+){0,2}$/)
      ]),
      typeOfUpdate: new FormControl('', [
        Validators.required
      ])
    });

    this.summaryForm = new FormGroup({
      updateComment: new FormControl('', [
        Validators.required
      ]),
    });
  }

  initializeUpdateAppForm() {
    this.updateAppForm = new FormGroup({
      newAppExecutableFile: new FormControl('', [
        Validators.required, FileValidator.maxContentSize(1048576000)  , FileTypeValidatior('.ipa')
      ]),
      newAppLogo: new FormControl('', [
        Validators.required, FileValidator.maxContentSize(5242880), FileTypeValidatior('.png')
      ]),
      newAppDemoVideo: new FormControl('', [
        FileValidator.maxContentSize(524288000), FileTypeValidatior(['.mov', '.mp4', '.m4v'])
      ]),
      newAppWhatsNew: new FormControl('', [
        Validators.required
      ]),
      newAppDescription: new FormControl('', [
        Validators.required
      ]),
      newAppInstruction: new FormControl('', [
        Validators.required
      ]),
      newAppBanner: new FormControl('', [
        Validators.required, FileValidator.maxContentSize(5242880), FileTypeValidatior(['.png', '.jpg'])
      ])
    });
  }
  disableFileInputs(){
    // disabling file Inputs at component load
    this.updateAppForm.get('newAppLogo').disable();
    this.updateAppForm.get('newAppExecutableFile').disable();
    this.updateAppForm.get('newAppBanner').disable();
  }
  
  moveToSummaryPage() {
    if (this.downloadUrlIpa && (this.updateAppPreForm.controls['newAppVersion'].value == this.app.version)) {
      this.updateAppPreForm.controls['newAppVersion'].setErrors({ 'duplicacyError': true });
      this.updateAppPreForm.controls['newAppVersion'].markAsTouched();
    } else {
      this.screenPayload = [];
      this.dragComponent.files.forEach((file) => {
        let obj = new Object();
        obj['name'] = file.name;
        obj['path'] = file.path;
        obj['progress'] = 100;
        obj['size'] = file.size;
        this.screenPayload.push(obj);
      })
      this.showSummayPage = true;
    }
  }

  updateApp(formData) {
    this.isSubmitting = true;
    let obj = {
      'name': this.updateAppPreForm.controls['newAppLinkName'].value,
      'version': this.updateAppPreForm.controls['newAppVersion'].value,
      'typeOfUpdate': this.updateAppPreForm.controls['typeOfUpdate'].value.id,
      'details': {
        'description': formData.newAppDescription,
        'instruction': formData.newAppInstruction,
        'whatsNew': formData.newAppWhatsNew
      },
      'logo': (this.downloadUrl) ? this.downloadUrl : this.app.logo,
      'logoName': (this.downloadUrl) ? this.files1[0]._files[0].name : this.app.logoName,
      "ipa": {
        "path": (this.downloadUrlIpa) ? this.downloadUrlIpa : this.app.ipa.path,
        "name": (this.downloadUrlIpa) ? this.files2[0]._files[0].name : this.app.ipa.name
      },
      "video": (this.downloadUrlVideo) ? { "path": this.downloadUrlVideo, "name": this.files3[0]._files[0].name } : (this.app.video) ? {
        "path": this.app.video.path,
        "name": this.app.video.name
      } : null,
      "banner": {
        "path": (this.downloadUrlBnnr) ? this.downloadUrlBnnr : this.app.banner.path,
        "name": (this.downloadUrlBnnr) ? this.files4[0]._files[0].name : this.app.banner.name
      },
      "screens": this.screenPayload,
      "isDisabled": !this.isChecked,
      "size": (this.downloadUrlIpa) ? this.files2[0]._files[0].size : this.app.size,
      "comment": this.summaryForm.controls['updateComment'].value
    }

    this.authenticationService.updateApp(obj, this.app.id)
    .then(async (data: any) => {
      if (data.errorCode == 0) {
        this.videoDeletedUrl && this.storageService.deleteFileFromStorageByUrl(this.videoDeletedUrl);
        this.downloadUrl = undefined; this.downloadUrlIpa = undefined;
        this.downloadUrlVideo = undefined; this.downloadUrlBnnr = undefined;
        this.closePanel();
        this.isSubmitting = false;
      } else if (data.errorCode == 4013) {
        await this.authenticationService.refreshTokenGenerater();
        this.updateApp(formData);
      }
    }).catch((err) => {
      this.isSubmitting = false;
    });

  }

  resetUpdateAppForm() {
    this.files1 = undefined; this.files2 = undefined; this.files3 = undefined; this.files4 = undefined;
    this.initializeUpdateAppPreForm();
    this.initializeUpdateAppForm();
    this.ngOnInit();
    this.downloadUrl && this.storageService.deleteFileFromStorageByUrl(this.downloadUrl);
    this.percentage = 0;
    this.downloadUrl = undefined;

    this.downloadUrlIpa && this.storageService.deleteFileFromStorageByUrl(this.downloadUrlIpa);
    this.percentageIpa = 0;
    this.downloadUrlIpa = undefined;

    console.log('reset update form', this.downloadUrlVideo);
    this.downloadUrlVideo && this.storageService.deleteFileFromStorageByUrl(this.downloadUrlVideo);
    this.percentageVideo = 0;
    this.downloadUrlVideo = undefined;

    this.downloadUrlBnnr && this.storageService.deleteFileFromStorageByUrl(this.downloadUrlBnnr);
    this.percentageBnnr = 0;
    this.downloadUrlBnnr = undefined;

    this.hideIpaField = true;
    this.hideAppLogoField = true;
    this.hideVideoField = true;
    this.hideBannerField = true;
    this.videoDeleted = false;
    this.videoDeletedUrl = "";
  }

  ngOnInit(): void {
    // Uploading New App IPA file
    this.updateAppForm.controls['newAppExecutableFile'].valueChanges.subscribe((files: any) => {
      this.ipaUploadTask && this.ipaUploadTask.cancel();
      if (this.updateAppForm.controls['newAppExecutableFile'].dirty && this.updateAppForm.controls['newAppExecutableFile'].valid) {
        if (!Array.isArray(files)) {
          this.files2 = [files];
        } else {
          this.files2 = files;
        }
        let observable = this.storageService.pushFileToStorage(this.storageIdentifier, 'ipa', this.files2);
        this.ipaUploadTask = observable.uploadTask;
        observable.uploadProgress$.subscribe(
          (percentage) => { this.percentageIpa = Math.round(percentage) },
          (error) => { console.log(error) }
        );
        observable.downloadUrl$.subscribe((downloadUrl) => {
          this.downloadUrlIpa = downloadUrl.split("&token")[0];
          this.updateAppPreForm.get('newAppVersion').enable();
        }, (error) => { console.log(error); }
        );
      }
    });

    // uploading new app Logo
    this.updateAppForm.controls['newAppLogo'].valueChanges.subscribe((files: any) => {
      if (this.updateAppForm.controls['newAppLogo'].dirty && this.updateAppForm.controls['newAppLogo'].valid) {
        if (!Array.isArray(files)) {
          this.files1 = [files];
        } else {
          this.files1 = files;
        }
        let observable = this.storageService.pushFileToStorage(this.storageIdentifier, 'logo', this.files1);
        observable.uploadProgress$.subscribe(
          (percentage) => { this.percentage = Math.round(percentage) },
          (error) => { console.log(error) }
        );
        observable.downloadUrl$.subscribe(
          (downloadUrl) => { this.downloadUrl = downloadUrl; },
          (error) => { console.log(error); }
        );
      }
    });

    // uploading new app Video file
    this.updateAppForm.controls['newAppDemoVideo'].valueChanges.subscribe((files: any) => {
      this.videoUplodTask && this.videoUplodTask.cancel();
      if (this.updateAppForm.controls['newAppDemoVideo'].valid) {
        if (!Array.isArray(files)) {
          this.files3 = [files];
        } else {
          this.files3 = files;
        }
        let observable = this.storageService.pushFileToStorage(this.storageIdentifier, 'video', this.files3);
        this.videoUplodTask = observable.uploadTask;
        observable.uploadProgress$.subscribe(
          (percentage) => { this.percentageVideo = Math.round(percentage) },
          (error) => { console.log(error) }
        );
        observable.downloadUrl$.subscribe(
          (downloadUrl) => { this.downloadUrlVideo = downloadUrl; },
          (error) => { console.log(error); }
        );
        this.videoDeleted = false;
        this.videoDeletedUrl = "";
      }
    });

    // uploading New App Banner image 
    this.updateAppForm.controls['newAppBanner'].valueChanges.subscribe((files: any) => {
      if (this.updateAppForm.controls['newAppBanner'].valid) {
        if (!Array.isArray(files)) {
          this.files4 = [files];
        } else {
          this.files4 = files;
        }
        let observable = this.storageService.pushFileToStorage(this.storageIdentifier, 'banner', this.files4);
        observable.uploadProgress$.subscribe(
          (percentage) => { this.percentageBnnr = Math.round(percentage) },
          (error) => { console.log(error) }
        );
        observable.downloadUrl$.subscribe(
          (downloadUrl) => { this.downloadUrlBnnr = downloadUrl; },
          (error) => { console.log(error); }
        );
      }
    });
  }

  closePanel() {
    this.resetUpdateAppForm();
    this.close.emit();
  }

  deleteVideoFromStorage() {
    this.videoDeletedUrl = this.app.video.path;
    this.app.video.path = "";
    this.app.video.name = "";
    this.hideVideoField = false;
    this.videoDeleted = true;
  }
  removeApplogo() {
    this.hideAppLogoField = false;
    this.updateAppForm.get('newAppLogo').enable();
  }
  removeExecutableFile(){
    this.hideIpaField = false;
    this.updateAppForm.get('newAppExecutableFile').enable();
  }
  removeBanner(){
    this.hideBannerField = false;
    this.updateAppForm.get('newAppBanner').enable(); 
  }
  imagePanelController(evt) {
    this.imageUrlUp.emit(evt);
  }

  ngAfterViewInit() {
    this.dragComponent.files = JSON.parse(JSON.stringify(this.app.screens));
    this.dragComponent.enableSubmit = true;
    this.service.currentApp = this.app
    this.cdr.detectChanges();
    var elementArry = document.querySelectorAll(".browse .mat-form-field-suffix");
    elementArry.forEach((element) => {
      element.innerHTML = "BROWSE";
      (<HTMLElement>element).style.setProperty("color", "#007CAD");
      (<HTMLElement>element).style.setProperty("top", "-3px");
    });
  }

  async toggleAppStatus() {
    this.isSubmitting = true;
    await this.authenticationService.toggleAppStatus(this.app.id, (this.isChecked) ? 'ENABLE' : 'DISABLE');
    this.isSubmitting = false;
  }
}
