<div class="add-app-wrapper">
    <div class="progress-bar" *ngIf="isSubmitting">
        <mat-spinner></mat-spinner>
    </div>
    <div class="add-link-form-title">
        <div class="panel-app-logo">
            <img height="35px" width="35px" [src]="currentAppLogo" alt="background_img" />
        </div>
        <div class="app-slider-title">
            {{app?.name}} <span>(Update)</span>
        </div>
        <div class="app-enable-slider" *ngIf="!showSummayPage">
            <mat-slide-toggle [(ngModel)]="isChecked" [color]="'primary'" (change)="toggleAppStatus()">
            </mat-slide-toggle>
            <span>{{isChecked ? 'Enabled' : 'Disabled'}}</span>
        </div>
        <div class="app-bunble-idf">
            Bundle identifier: {{app?.bIdentifier}}
        </div>
        <div class="cancel-btn" (click)="closePanel()">
            ✕
        </div>
    </div>
    <hr>
    <div class="add-app-form">
        <div [hidden]="showSummayPage">
            <form [formGroup]="updateAppForm" class="new-app-section">
                <div class="new-app-section1">
                    <form [formGroup]="updateAppPreForm" class="">
                        <div class="new-app-section1-pre-form">
                            <mat-form-field appearance="outline">
                                <mat-label>App name *</mat-label>
                                <input matInput formControlName="newAppLinkName" maxlength="30" />
                                <mat-error *ngIf="updateAppPreForm.get('newAppLinkName').hasError('required')">
                                    App name is required
                                </mat-error>
                                <mat-error *ngIf="updateAppPreForm.get('newAppLinkName').hasError('pattern')">
                                    Only Alphanumeric character is allowed
                                </mat-error>
                                <mat-error *ngIf="updateAppPreForm.get('newAppLinkName').hasError('userNameExists')">
                                    App name already taken
                                </mat-error>
                                <mat-hint align="end">{{updateAppPreForm.get('newAppLinkName').value.length}} /
                                    30
                                </mat-hint>
                                <mat-hint align="start" *ngIf="updateAppPreForm.get('newAppLinkName').pending">
                                    Loading...
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>Version Number*</mat-label>
                                <input matInput formControlName="newAppVersion" />
                                <mat-error *ngIf="updateAppPreForm.get('newAppVersion').hasError('required')">
                                    Version number is required
                                </mat-error>
                                <mat-error *ngIf="updateAppPreForm.get('newAppVersion').hasError('pattern')">
                                    Only dot and numbers are allowed e.g. x, x.x, x.x.x
                                </mat-error>
                                <mat-error *ngIf="updateAppPreForm.get('newAppVersion').hasError('duplicacyError')">
                                    Version number can't be same or less than previous
                                </mat-error>
                                <mat-hint align="end">{{updateAppPreForm.get('newAppVersion').value.length}} /
                                    64
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>Type of update *</mat-label>
                                <mat-select formControlName="typeOfUpdate">
                                    <mat-option *ngFor="let update of typeOfUpdate" [value]="update">
                                        {{update.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="updateAppPreForm.get('typeOfUpdate').hasError('required')">
                                    Bundle identifier is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </form>
                    <div class="add-app-post-form-wrapper">
                        <div [hidden]="hideIpaField">
                            <mat-form-field appearance="outline" class="browse">
                                <mat-label>Executable file *</mat-label>
                                <ngx-mat-file-input formControlName="newAppExecutableFile"
                                    placeholder="Basic outline placeholder" [accept]="'.ipa'"></ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                                <mat-error *ngIf="updateAppForm.get('newAppExecutableFile').hasError('required')">
                                    Executable file is <strong>required</strong>
                                </mat-error>
                                <mat-error *ngIf="updateAppForm.get('newAppExecutableFile').hasError('maxContentSize')">
                                    The total size must not exceed 1GB
                                </mat-error>
                                <mat-error *ngIf="updateAppForm.get('newAppExecutableFile').hasError('typeError')">
                                    Allowed file type: IPA
                                </mat-error>
                                <mat-error *ngIf="updateAppForm.get('newAppExecutableFile').hasError('duplicacyError')">
                                    File name can't be same as previous
                                </mat-error>
                                <mat-hint align="start"> Allowed file type: IPA </mat-hint>
                                <mat-hint align="end">{{percentageIpa}} %</mat-hint>
                            </mat-form-field>
                        </div>
                        <div *ngIf="hideIpaField" style="">
                            <update-field [label]="'Executable file *'" [fileName]="app?.ipa?.name"
                                (remove)="removeExecutableFile()"></update-field>
                        </div>

                        <div [hidden]="hideAppLogoField">
                            <mat-form-field appearance="outline" class="browse">
                                <mat-label>App Icon *</mat-label>
                                <ngx-mat-file-input formControlName="newAppLogo" placeholder="Basic outline placeholder"
                                    [accept]="'image/png'"></ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                                <mat-error *ngIf="updateAppForm.get('newAppLogo').hasError('required')">
                                    Logo is <strong>required</strong>
                                </mat-error>
                                <mat-error *ngIf="updateAppForm.get('newAppLogo').hasError('maxContentSize')">
                                    The total size must not exceed 50kb
                                </mat-error>
                                <mat-error *ngIf="updateAppForm.get('newAppLogo').hasError('typeError')">
                                    Allowed file type: PNG(1024x1024)
                                </mat-error>
                                <mat-error *ngIf="updateAppForm.get('newAppLogo').hasError('duplicacyError')">
                                    File name can't be same as previous
                                </mat-error>
                                <mat-hint align="start">Allowed file type: PNG(1024x1024)
                                </mat-hint>
                                <mat-hint align="end">{{percentage}} %</mat-hint>
                            </mat-form-field>
                        </div>
                        <div *ngIf="hideAppLogoField" style="">
                            <update-field [label]="'App Icon *'" [fileName]="app?.logoName"
                                (remove)="removeApplogo()"></update-field>
                        </div>
                        <div [hidden]="hideVideoField">
                            <mat-form-field appearance="outline" class="browse">
                                <mat-label>Demo Video</mat-label>
                                <ngx-mat-file-input formControlName="newAppDemoVideo"
                                    placeholder="Basic outline placeholder" [accept]="'video/mp4 , .mov , video/m4v'">
                                </ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                                <mat-error *ngIf="updateAppForm.get('newAppDemoVideo').hasError('maxContentSize')">
                                    The total size must not exceed 500 MB
                                </mat-error>
                                <mat-error *ngIf="updateAppForm.get('newAppDemoVideo').hasError('typeError')">
                                    Allowed file type: M4V, MP4, and MOV format
                                </mat-error>
                                <mat-error *ngIf="updateAppForm.get('newAppDemoVideo').hasError('duplicacyError')">
                                    File name can't be same as previous
                                </mat-error>
                                <mat-hint align="start"> Allowed file type: M4V, MP4, and MOV format </mat-hint>
                                <mat-hint align="end">{{percentageVideo}} %</mat-hint>
                            </mat-form-field>
                        </div>
                        <div *ngIf="hideVideoField">
                            <update-field [label]="'Demo Video'" [fileName]="app?.video?.name"
                                (remove)="deleteVideoFromStorage()"></update-field>
                        </div>
                    </div>
                </div>
                <div class="new-app-section2">
                    <mat-form-field appearance="outline">
                        <mat-label>What's new *</mat-label>
                        <textarea matInput rows="12" style="resize: none;" formControlName="newAppWhatsNew"
                            maxlength="500"></textarea>
                        <mat-error *ngIf="updateAppForm.get('newAppWhatsNew').hasError('required')">
                            App description is required
                        </mat-error>
                        <mat-hint align="end">{{updateAppForm.get('newAppWhatsNew').value.length}} / 500
                        </mat-hint>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Description *</mat-label>
                        <textarea matInput rows="12" style="resize: none;" formControlName="newAppDescription"
                            maxlength="4000"></textarea>
                        <mat-error *ngIf="updateAppForm.get('newAppDescription').hasError('required')">
                            App description is required
                        </mat-error>
                        <mat-hint align="end">{{updateAppForm.get('newAppDescription').value.length}} / 4000
                        </mat-hint>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Instructions *</mat-label>
                        <textarea matInput rows="12" style="resize: none;" formControlName="newAppInstruction"
                            maxlength="500"></textarea>
                        <mat-error *ngIf="updateAppForm.get('newAppInstruction').hasError('required')">
                            App Instructions is required
                        </mat-error>
                        <mat-hint align="end">{{updateAppForm.get('newAppInstruction').value.length}} / 500
                        </mat-hint>
                    </mat-form-field>
                </div>
                <div class="new-app-section3">
                    <div>
                        <div [hidden]="hideBannerField">
                            <mat-form-field appearance="outline" class="browse">
                                <mat-label>App Banner *</mat-label>
                                <ngx-mat-file-input formControlName="newAppBanner"
                                    placeholder="Basic outline placeholder" [accept]="'image/png , image/jpg , .jpg'">
                                </ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                                <mat-error *ngIf="updateAppForm.get('newAppBanner').hasError('required')">
                                    Banner file is <strong>required</strong>
                                </mat-error>
                                <mat-error *ngIf="updateAppForm.get('newAppBanner').hasError('maxContentSize')">
                                    The total size must not exceed 50kb
                                </mat-error>
                                <mat-error *ngIf="updateAppForm.get('newAppBanner').hasError('typeError')">
                                    Allowed file type: PNG, JPG(788 × 446)
                                </mat-error>
                                <mat-error *ngIf="updateAppForm.get('newAppBanner').hasError('duplicacyError')">
                                    File name can't be same as previous
                                </mat-error>
                                <mat-hint align="start">Allowed file type: PNG, JPG(788 × 446)
                                </mat-hint>
                                <mat-hint align="end">{{percentageBnnr}} %</mat-hint>
                            </mat-form-field>
                        </div>
                        <div *ngIf="hideBannerField">
                            <update-field [label]="'App Banner *'" [fileName]="app?.banner?.name"
                                (remove)="removeBanner()"></update-field>
                        </div>
                    </div>
                    <app-drap-and-drop #ref [storageIdentifier]="storageIdentifier" [operation]="'update'"
                        (imageUrl)="imagePanelController($event)">
                    </app-drap-and-drop>
                </div>
            </form>
        </div>
        <div [hidden]="!showSummayPage">
            <div class="summary-container">
                <div class="summary-heading">
                    <p><span>Not so fast recruit!</span> <br>
                        <span>Tell us about the changes you've made</span></p>
                </div>
                <div class="summary-list">
                    <ul>
                        <li *ngIf="app.name != updateAppPreForm.get('newAppLinkName').value">
                            App name updated
                        </li>
                        <li *ngIf="downloadUrl">
                            App Icon updated
                        </li>
                        <li *ngIf="app.details.description != updateAppForm.get('newAppDescription').value">
                            Description Updated
                        </li>
                        <li *ngIf="app.details.instruction != updateAppForm.get('newAppInstruction').value">
                            Instructions updated
                        </li>
                        <li *ngIf="downloadUrlIpa">
                            Executable updated
                        </li>
                        <li *ngIf="downloadUrlIpa">
                            Version Updated
                        </li>
                        <li *ngIf="downloadUrlVideo || videoDeleted">
                            Demo Video updated
                        </li>
                        <li *ngIf="downloadUrlBnnr">
                            App Banner Updated
                        </li>
                        <li *ngIf="JSON.stringify(app.screens)!= JSON.stringify(screenPayload)">
                            App screen shot updated
                        </li>
                    </ul>
                </div>
                <div class="summary-comment">
                    <form [formGroup]="summaryForm">
                        <mat-form-field appearance="outline">
                            <mat-label>Update comments *</mat-label>
                            <textarea matInput rows="12" style="resize: none;" formControlName="updateComment"
                                maxlength="500"></textarea>
                            <mat-error *ngIf="summaryForm.get('updateComment').hasError('required')">
                                comment is required
                            </mat-error>
                            <mat-hint align="end">{{summaryForm.get('updateComment').value.length}} / 500
                            </mat-hint>
                        </mat-form-field>
                    </form>
                </div>
            </div>
        </div>
        <hr>
        <div class="submit-new-app">
            <div *ngIf="!showSummayPage">
                <button [disabled]="updateAppPreForm.pending || !updateAppPreForm.valid || !updateAppForm.valid || !ref.enableSubmit 
                || files1 && files1.length > 0 && !downloadUrl || files2 && files2.length > 0 && !downloadUrlIpa ||
                files3 && files3.length > 0 && !downloadUrlVideo || files4 && files4.length > 0 && !downloadUrlBnnr"
                    (click)="moveToSummaryPage()">Update App</button>
            </div>
            <div *ngIf="showSummayPage">
                <span (click)="showSummayPage = false">GO BACK</span>
                <button [disabled]="!summaryForm.valid" (click)="updateApp(updateAppForm.value)">Update App</button>
            </div>
        </div>
    </div>
</div>